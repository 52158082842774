// import QRCode from "qrcode.react";
import {
  Box,
  Flex,
  Grid,
  Image,
  Text,
  useToast,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import giangsinh1 from "./images/gs1.jpeg";
import cherrylove from "./images/cherrylove.mp3";

import nguyet1 from "./images/nguyet1.jpeg";
import nguyet11 from "./images/nguyet11.jpeg";
import nguyet13 from "./images/nguyet13.jpeg";
import nguyet15 from "./images/nguyet15.jpeg";
import nguyet2 from "./images/nguyet2.jpeg";
import nguyet21 from "./images/nguyet21.jpeg";
import nguyet22 from "./images/nguyet22.jpeg";
import nguyet23 from "./images/nguyet23.png";
import nguyet25 from "./images/nguyet25.png";
import nguyet26 from "./images/nguyet26.jpeg";
import nguyet27 from "./images/nguyet27.png";
import nguyet28 from "./images/nguyet28.jpeg";
import nguyet29 from "./images/nguyet29.jpeg";
import nguyet3 from "./images/nguyet3.jpeg";
import nguyet31 from "./images/nguyet31.jpeg";
import nguyet32 from "./images/nguyet32.jpeg";
import nguyet34 from "./images/nguyet34.jpeg";
import nguyet36 from "./images/nguyet36.png";
import nguyet5 from "./images/nguyet5.jpeg";
import nguyet9 from "./images/nguyet9.png";
import santa from "./images/dichoi.gif";
import qua2 from "./images/gif1.gif";

function App() {
  const images = [
    nguyet28,
    nguyet32,
    nguyet36,
    nguyet9,
    nguyet22,
    nguyet21,
    nguyet2,
    nguyet23,
    nguyet13,
    nguyet3,
    nguyet34,
    nguyet29,
    nguyet1,
    nguyet31,
    nguyet11,
    nguyet25,
    nguyet15,
    nguyet26,
    nguyet27,
  ];
  const imagesCute = [nguyet5, nguyet31, nguyet9, nguyet2];

  const toast = useToast();

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const [count, setCount] = useState(0);
  const [isCute, setIsCute] = useState(false);
  const [qua, setQua] = useState(true);
  const [noel, setNoel] = useState(false);
  const [getbgnguyet22, setGetbgNguyet22] = useState(true);
  const [watchVideo, setWatchVideo] = useState(false);
  const [question, setQuestion] = useState(false);
  const [stepQuestion, setStepQuestion] = useState("step1");
  const [positionRandomX, setPositionRandomX] = useState(5);
  const [positionRandomY, setPositionRandomY] = useState(5);
  const [imageCutePro, setImageCutePro] = useState(nguyet5);
  const audioMerry = new Audio(`${cherrylove}`);

  const playAudio = () => {
    audioMerry.play();
  };
  const pauseAudio = () => {
    audioMerry.pause();
    audioMerry.currentTime = 0;
  };

  useEffect(() => {
    setTimeout(() => {
      setCount((prevCount) => prevCount + 1);
    }, 30000);
  }, [count]);

  useEffect(() => {
    if (noel) {
      setTimeout(() => {
        setQua(false);
      }, 5000);
    }
  }, [qua, noel]);

  const randomPosition = () => {
    function randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
    const rndInt1 = randomIntFromInterval(2, 8);
    const rndInt2 = randomIntFromInterval(2, 8);
    setPositionRandomX(rndInt1);
    setPositionRandomY(rndInt2);
  };

  const handelStep = () => {
    randomPosition();

    setImageCutePro(shuffle(imagesCute)[0]);
    switch (stepQuestion) {
      case "step1":
        setStepQuestion("step2");

        break;
      case "step2":
        setStepQuestion("step3");

        break;
      case "step3":
        setStepQuestion("step4");
        setGetbgNguyet22(true);
        pauseAudio();

        break;
      case "step4":
        pauseAudio();
        setWatchVideo(true);
        setQuestion(false);

        break;

      default:
        break;
    }
  };

  return (
    <>
      {/* <Box p="100px">
        <QRCode value="https://christmas-iota.vercel.app/" size={290} />
      </Box> */}

      <Box
        // display="none"
        p={watchVideo ? "0px" : "20px"}
        position="relative"
        h="100vh"
        overflow="hidden"
        // backgroundImage={
        //   !question ?  : `url('${shuffle(images)[0]}')`
        // }
        backgroundImage={`url('${giangsinh1}')`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        bgSize="cover"
      >
        {qua && (
          <Box>
            <Box
              top="50%"
              position="absolute"
              left="50%"
              transform="translate(-50%,-50%)"
              boxShadow="inner"
              borderRadius="16px"
              overflow="hidden"
              w={"80%"}
            >
              {noel ? (
                <Box>
                  <Image src={santa} w="100%" h="100%" />
                </Box>
              ) : (
                <Box position="relative">
                  <Text
                    top="50%"
                    position="absolute"
                    left="50%"
                    transform="translate(-50%,-50%)"
                    backgroundColor="blue.500"
                    padding="8px 16px"
                    color="white"
                    borderRadius="8px"
                    cursor="pointer"
                    fontWeight="700"
                    onClick={() => {
                      setNoel(true);
                      playAudio();
                    }}
                  >
                    {`To be contiue >.<`}
                  </Text>
                  <Image src={qua2} w="100%" h="100%" />
                </Box>
              )}
            </Box>
          </Box>
        )}
        {!qua && (
          <Box>
            {!watchVideo && question && (
              <Box
                backgroundImage={`url('${
                  stepQuestion === "step4" ? nguyet22 : shuffle(imagesCute)[0]
                }')`}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                bgSize="contain"
                w="70%"
                h="70%"
                top="50%"
                position="absolute"
                left="50%"
                transform="translate(-50%,-50%)"
              />
            )}
            {isMobile && !watchVideo && (
              <Box
                backgroundImage={`url('${shuffle(images)[0]}')`}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                bgSize="contain"
                w="70%"
                h="70%"
                top="50%"
                position="absolute"
                left="50%"
                transform="translate(-50%,-50%)"
              />
            )}
            {watchVideo && (
              <Box h="100%" w="100%">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/LjpJ7ew9VMI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Box>
            )}
            {!question && !watchVideo && (
              <>
                <Box
                  overflow="hidden"
                  h="600px"
                  w="1200px"
                  top="50%"
                  position="absolute"
                  left="50%"
                  transform="translate(-50%,-50%)"
                >
                  <Grid
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                      md: "repeat(6, 1fr)",
                    }}
                    gap={0}
                    borderRadius="24px"
                    overflow="hidden"
                    // w="100%"
                  >
                    {images.map((item, i) => (
                      // {shuffle(images).map((item) => (
                      // <Box w="200px" h="200px" overflow="hidden">
                      //   <Image w="100%" h="100%" src={item} />
                      // </Box>
                      <Box
                        key={i}
                        w="200px"
                        h="200px"
                        backgroundImage={`url('${item}')`}
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        bgSize="cover"
                      />
                    ))}
                  </Grid>
                </Box>

                <Box
                  position="absolute"
                  p={{ base: "15px", md: "20px" }}
                  borderRadius={{ base: "16px", md: "24px" }}
                  top={`${positionRandomX}0%`}
                  left={`${positionRandomY}0%`}
                  transform="translate(-50%,-50%)"
                  bg="rgba(0,0,0,0.5)"
                  border="1px solid"
                  boxShadow="5px 8px 15px 5px #000000"
                >
                  <Text
                    fontSize={{ base: "14px", md: "24px" }}
                    lineHeight={{ base: "20px", md: "30px" }}
                    fontWeight="600"
                    color="white"
                    mb={{ base: "20px", md: "40px" }}
                  >
                    {isCute
                      ? "Chúc mừng bạn đã chọn được kết quả đúng ạaa, hihi :>"
                      : "Bạn nam chuẩn bị dẫn bạn trong ảnh này đi chơi có cute honggg :>"}
                  </Text>
                  <Flex justifyContent={isCute ? "center" : "space-between"}>
                    {isCute ? (
                      <Box
                        p={{ base: "4px 8px", md: "6px 10px" }}
                        bgColor="orange.300"
                        opacity="0.9"
                        borderRadius="full"
                      >
                        <Text
                          fontSize={{ base: "12px", md: "14px" }}
                          lineHeight={{ base: "16px", md: "18px" }}
                          fontWeight="500"
                          color="white"
                          variant="ghost"
                          cursor="pointer"
                          onClick={() => {
                            setQuestion(true);
                            randomPosition();
                          }}
                        >
                          Tiếp tục hoyy
                        </Text>
                      </Box>
                    ) : (
                      <>
                        <Box
                          p={{ base: "4px 8px", md: "6px 10px" }}
                          bgColor="orange.300"
                          opacity="0.9"
                          borderRadius="full"
                        >
                          <Text
                            fontSize={{ base: "12px", md: "14px" }}
                            lineHeight={{ base: "16px", md: '"18px"' }}
                            fontWeight="500"
                            color="white"
                            variant="ghost"
                            cursor="pointer"
                            onClick={() => {
                              // toast({
                              //   description: "Khum đâu bạn ni rất dễ thươngg á",
                              //   status: "success",
                              //   duration: 5000,
                              //   isClosable: true,
                              // });
                              setIsCute(true);
                            }}
                          >
                            Rất nhiều ạaaa
                          </Text>
                        </Box>
                        <Box
                          p="6px 10px"
                          bgColor="orange.300"
                          opacity="0.9"
                          borderRadius="full"
                        >
                          <Text
                            fontSize={{ base: "12px", md: "14px" }}
                            lineHeight={{ base: "16px", md: '"18px"' }}
                            fontWeight="500"
                            color="white"
                            variant="ghost"
                            cursor="pointer"
                            onClick={() => randomPosition()}
                          >
                            Chê ạaaa
                          </Text>
                        </Box>
                      </>
                    )}
                  </Flex>
                </Box>
              </>
            )}
            {question && !watchVideo && (
              <Box
                position="absolute"
                p={{
                  base: "15px",
                  md: stepQuestion === "step4" ? "30px" : "20px",
                }}
                borderRadius={{ base: "16px", md: "24px" }}
                top={`${positionRandomX}0%`}
                left={`${positionRandomY}0%`}
                transform="translate(-50%,-50%)"
                bg="rgba(0,0,0,0.5)"
                border="1px solid"
                boxShadow="5px 8px 15px 5px #000000"
              >
                <Text
                  fontSize={{ base: "14px", md: "24px" }}
                  lineHeight={{ base: "20px", md: "30px" }}
                  fontWeight="600"
                  color="white"
                  mb="40px"
                  m={stepQuestion === "step4" && "0px"}
                >
                  {stepQuestion === "step1" && "Công chúa muốn đi đâu ạaaa"}
                  {stepQuestion === "step2" && "Tụi mình uống gì nè"}
                  {stepQuestion === "step3" && "Uống rồi đến lúc ăn hoy"}
                  {stepQuestion === "step4" && (
                    <>
                      <Text>
                        {"Xong rồi, giờ thì lên xe để bạn ấy đưa đi nàooo :>"}
                      </Text>
                      {/* <Text
                    display="inline"
                    fontSize="14px"
                    fontWeight="300"
                    cursor="pointer"
                    onClick={() => handelStep()}
                  >
                    Click me
                  </Text> */}
                    </>
                  )}
                </Text>
                {stepQuestion !== "step4" && (
                  <Flex
                    justifyContent="space-between"
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    <Box
                      p="6px 10px"
                      bgColor="orange.300"
                      opacity="0.9"
                      borderRadius="full"
                      mb={{ base: "12px", md: "0px" }}
                    >
                      <Text
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight={{ base: "16px", md: '"18px"' }}
                        fontWeight="500"
                        color="white"
                        variant="ghost"
                        cursor="pointer"
                        onClick={() => handelStep()}
                        textAlign="center"
                      >
                        {stepQuestion === "step1" && "Đi biển ạ"}
                        {stepQuestion === "step2" && "Bia"}

                        {stepQuestion === "step3" &&
                          "Mua mực xé, bimbim ở vinmart"}
                      </Text>
                    </Box>

                    <Box
                      p="6px 10px"
                      bgColor="orange.300"
                      opacity="0.9"
                      borderRadius="full"
                    >
                      <Text
                        fontSize={{ base: "12px", md: "14px" }}
                        lineHeight={{ base: "16px", md: '"18px"' }}
                        fontWeight="500"
                        color="white"
                        variant="ghost"
                        cursor="pointer"
                        onClick={() => handelStep()}
                        textAlign="center"
                      >
                        {stepQuestion === "step1" && "Đĩa bay đê"}
                        {stepQuestion === "step2" && "Trà tắc"}
                        {stepQuestion === "step3" && "Mua mồi ở quán nhậu :v"}
                      </Text>
                    </Box>
                  </Flex>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

export default App;
